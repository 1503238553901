<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" content="提现账户管理" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="flex justify-end">
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">新增提现账户</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>

			<el-table-column prop="type" label="账户类型" width="100">
				<template slot-scope="scope">
					<div v-if="scope.row.type==1">微信</div>
					<div v-if="scope.row.type==2">支付宝</div>
					<div v-if="scope.row.type==3">银行卡</div>
				</template>
			</el-table-column>
			<el-table-column prop="account_number" label="帐号" width="200"></el-table-column>
			<el-table-column prop="account_name" label="帐号姓名" width="200"></el-table-column>
			<el-table-column prop="subbranch" label="银行" width="200">
				<template slot-scope="scope">
					{{scope.row.subbranch}}
					<span v-if="scope.row.opening_bank"> - {{scope.row.opening_bank}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="phone" label="手机号码" width="200"></el-table-column>
			<el-table-column prop="is_default" label="是否默认">
				<template slot-scope="scope">
					<div v-if="scope.row.is_default==1">是</div>
				</template>
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button size="mini" @click="toEdit(scope.row)">编辑</el-button>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: []
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(user) {
				if (user) {
					// 编辑
					this.$router.push(`/account/bankcard/edit/${user.id}`);
				} else {
					// 新增
					this.$router.push("/account/bankcard/edit");
				}
			},
			loadData() {
				this.loading = true;
				this.$api.store.getAccounts().then(res => {
					this.loading = false;
					this.tableData = res.data.data;
				});
			},
			del(item) {
				const loading = this.$loading()
				this.$api.store.delAccount({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						// 保存成功
						this.$message.success(`操作成功`);
						this.loadData()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			}
		}
	};
</script>
<style scoped lang="scss">

</style>
